// Global

// Countdown
function getTimeRemaining(endtime){
  var t = Date.parse(endtime) - Date.parse(new Date());
  var seconds = Math.floor( (t/1000) % 60 );
  var minutes = Math.floor( (t/1000/60) % 60 );
  var hours = Math.floor( (t/(1000*60*60)) % 24 );
  var days = Math.floor( t/(1000*60*60*24) % 30.4 );
  var months = Math.floor( t/(1000*60*60*24*30.4) % 12 );
  var years = Math.floor( t/(1000*60*60*24*30.4*12));
  return {
    'total': t,
    'years': years,
    'months': months,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function initializeClock(id, endtime){
  var clock = document.getElementById(id);
  var yearsSpan = clock.querySelector('.years');
  var monthsSpan = clock.querySelector('.months');
  var daysSpan = clock.querySelector('.days');
  var hoursSpan = clock.querySelector('.hours');
  var minutesSpan = clock.querySelector('.minutes');
  var secondsSpan = clock.querySelector('.seconds');

function updateClock(){
  var t = getTimeRemaining(endtime);

    yearsSpan.innerHTML = ('0' + t.years).slice(-2);
    monthsSpan.innerHTML = ('0' + t.months).slice(-2);
    daysSpan.innerHTML = ('0' + t.days).slice(-2);
    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    if(t.total<=0){
      clearInterval(timeinterval);
    }
  }

  updateClock();
  var timeinterval = setInterval(updateClock,1000);
}

var deadline = 'October 01 2017 09:00:00 UTC-0800';
$(function () {
  if ( $('#clockdiv').length ){
    initializeClock('clockdiv', deadline);
  }
});
// end of Countdown

function sticky_relocate() {
    var window_top = $(window).scrollTop();
    var div_top = $('#sticky-anchor').offset().top;
    if (window_top > div_top) {
        $('#sticky').addClass('stick');
        $('#sticky-anchor').height($('#sticky').outerHeight());
    } else {
        $('#sticky').removeClass('stick');
        $('#sticky-anchor').height(0);
    }
}

function sticky_relocate_2() {
    var window_top = $(window).scrollTop();
    var div_top = $('#sticky-anchor-2').offset().top;
    if (window_top > div_top) {
        $('#sticky-2').addClass('stick');
        $('#sticky-2').fadeIn();
        $('#sticky').fadeOut("slow");
        $('#sticky-anchor-2').height($('#sticky-2').outerHeight());
    } else {
        $('#sticky-2').removeClass('stick');
        $('#sticky').fadeIn();
        $('#sticky-2').hide();
        $('#sticky-anchor-2').height(0);
    }
}

// On window resize scroll
$(window).on("load resize scroll",function(){
    var viewportWidth = $(window).width();
    var url = window.location.href;

    if (viewportWidth > 991){
        $('.owl-nav').show();
        $('.mobile-second').hide();
    }
    if (viewportWidth < 991){
        $('.owl-nav').hide();
        $('.carousel-dektop').hide();
        $('.carousel-mobile').show();
        if (url.indexOf("convention") > -1){
            $('.mobile-second-nav-convention').show();
            console.log('convention');
        }
        if (url.indexOf("about.php") > -1){
            $('.mobile-second-nav-about').show();
        }
        if (url.indexOf("hotel") > -1){
            $('.mobile-second-nav-hotel').show();
        }
        if (url.indexOf("sponsorship") > -1){
            $('.mobile-second-nav-sponsorship').show();
        }
        if (url.indexOf("registration.php") > -1){
            $('.mobile-second-nav-registration').show();
        }
    }
    // JQuery matchheight plugin
    if (viewportWidth > 991) {
        $('.boxH').matchHeight(false);
        $('.carousel-dektop').show();
        $('.carousel-mobile').hide();
    }

    // Home page video
    if (viewportWidth > 991){
        var v_height = $('.video').height();
        $('header').css('height',v_height);
    }
    if (viewportWidth < 991){
        $('header').css('height',600);
    }

});

// show-hide tabs and content in pages
$(function() {
    // define variables
    var viewportWidth = $(window).width();
    var url = window.location.href;

    if (viewportWidth > 991){
        $('.owl-nav').show();
    }
    if (viewportWidth < 991){
        $('.owl-nav').hide();
    }

    $("#menu-about a").click(function() {
        curPage="";
        if (curPage.length) {
            $("#"+curPage).hide();
        }
        curPage=$(this).data("page");
        $('.about').hide();
        $("#"+curPage).show();
    });

    // $("#menu-biz-plan a").click(function() {
    //     curPage="";
    //     if (curPage.length) {
    //         $("#"+curPage).hide();
    //     }
    //     curPage=$(this).data("page");
    //     $('.business-plan-hook').hide();
    //     $("#"+curPage).show();
    // });

    // $("#menu-events a").click(function() {
    //     curPage="";
    //     if (curPage.length) {
    //         $("#"+curPage).hide();
    //     }
    //     curPage=$(this).data("page");
    //     $('.events-hook').hide();
    //     $("#"+curPage).show();
    // });

    $("li").click(function() {
        $("li").removeClass("active");
        $(this).addClass("active");
    });

    $("#menu-biz-plan li").click(function() {
        $("li").removeClass("active-biz-plan");
        $(this).addClass("active-biz-plan");
    });

    $("#menu-events li").click(function() {
        $("li").removeClass("active-events");
        $(this).addClass("active-events");
    });

    if ( window.location.pathname == '/' ){
        if($("#sticky-anchor").length) {
            $(window).scroll(sticky_relocate);
            sticky_relocate();
        }
        if($("#sticky-anchor-2").length) {
            $(window).scroll(sticky_relocate_2);
            sticky_relocate_2();
        }
    }

    // add active class to navigation sticky-2
    $(".nav-items li").each(function() {
        var link = $(this).children("a").attr("href");
        path = document.location.href.split('/').pop().split(/\?|#/).shift();
        if(link.indexOf(path) > -1){
           $(this).addClass("active");
           return;
        }
    });

    $('.business-plan-hook').eq(0).css('display','block');
    $('.events-hook').eq(0).css('display','block');

    $.fn.showMap = function(options, addr){
      options = $.extend({
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }, options);
      var map = new google.maps.Map(document.getElementById($(this).attr('id')), options);
      // code cut from this example as not relevant
    };

    // if sticky-anchor position is fixed, hide it, and show sticky-achor-2 instead
    if ($('.stick').length) {
        $('#sticky-anchor').hide();
        $('#sticky-2').show();
    }

    if ( window.location.pathname == '/' ){
        $('#sticky-2').css('display','none');
    } else {
        // Other page
        $('#sticky-2').css({
            'display':'block',
            'position':'fixed',
            'top': 0,
            'z-index': 1000
        });
    }

    // About videos
    $('.btn-greenest').on('click', function(){
        $('.video-overlay').show();
        $('.aapavideocont iframe').attr('src', 'https://www.youtube.com/embed/gybaBNBPMAs?rel=0&autoplay=1');
    });
    $('.btn-foundations').on('click', function(){
        $('.video-overlay').fadeIn();
        $('.aapavideocont iframe').attr('src', 'https://www.youtube.com/embed/xADUiMOiA60?rel=0&autoplay=1');
    });
    $('.btn-rail').on('click', function(){
        $('.video-overlay').fadeIn();
        $('.aapavideocont iframe').attr('src', 'https://www.youtube.com/embed/mq3HZXKwZ-s?rel=0&autoplay=1');
    });
    $('.btn-energy').on('click', function(){
        $('.video-overlay').fadeIn();
        $('.aapavideocont iframe').attr('src', 'https://www.youtube.com/embed/mq3HZXKwZ-s?rel=0&autoplay=1');
    });
    $('.btn-policy').on('click', function(){
        $('.video-overlay').fadeIn();
        $('.aapavideocont iframe').attr('src', 'https://www.youtube.com/embed/HmmgI_Mr6cI?rel=0&autoplay=1');
    });

    $('#videoclose').on('click', function(){
        $('.video-overlay').fadeOut();
        $('.aapavideocont iframe').attr('src', '');
    });

    $('.btn-home-video-1').on('click', function(){
        $(this).hide();
        $('.youtube-vid').show();
        $('.youtube-vid iframe').attr('src', 'https://www.youtube.com/embed/Dnv1CL0DtVc?rel=0&autoplay=1');
    });
    $('.trigger-home-video-2').on('click', function(){
        $(this).hide();
        $('.vimeo-vid').show();
        $('.vimeo-vid iframe').attr('src', 'https://player.vimeo.com/video/146307839?title=0&byline=0&portrait=0&autoplay=1');
    });

    // Convention detail overlays
    $('.detail-1').on('click', function(){
        $('.convention-detail-1').fadeIn();
    });
    $('.detail-2').on('click', function(){
        $('.convention-detail-exhibitors').fadeIn();
    });
    $('.detail-3').on('click', function(){
        $('.convention-detail-non-member-delegate').fadeIn();
    });
    $('.detail-4').on('click', function(){
        $('.convention-detail-media').fadeIn();
    });
    $('.detail-5').on('click', function(){
        $('.convention-detail-spouse').fadeIn();
    });
    $('.detail-6').on('click', function(){
        $('.convention-detail-honorary').fadeIn();
    });

    // Hide details overlay if clicked on
    $('.detail-target').on('click', function(){
        $(this).fadeOut();
    });

    // Owl carousel

    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:0,
        nav:true,
        autoplay:true,
        dots: false,
        animateOut: 'fadeOut',
        lazyLoad: true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    });
    $( ".owl-prev").html('<img src="images/arrow-lt.png" alt="previous image">');
    $( ".owl-next").html('<img src="images/arrow-rt.png" alt="next image">');


    $('#toggle-menu').click(function(){
        $(this).toggleClass('menu-is-active');
        $('#navbar').toggleClass('slideout');
        $(".aapalogo").removeClass('up').addClass('down');
    });

    // Show hide Mobile nav based on URL

    if (viewportWidth < 991){
        if (url.indexOf("convention") > -1){
            $('.mobile-second-nav-convention').show();
            console.log('convention');
        }
        if (url.indexOf("about.php") > -1){
            $('.mobile-second-nav-about').show();
        }
        if (url.indexOf("hotel") > -1){
            $('.mobile-second-nav-hotel').show();
        }
        if (url.indexOf("sponsorship") > -1){
            $('.mobile-second-nav-sponsorship').show();
        }
        if (url.indexOf("registration.php") > -1){
            $('.mobile-second-nav-registration').show();
        }
    }

    // Home page video
    if (viewportWidth > 991){
        var v_height = $('.video').height();
        $('header').css('height',v_height);
    }
    if (viewportWidth < 991){
        $('header').css('height',600);
    }

    var obj;
    // Home page About the City
    $('.btn-about').click(function() {
        sessionStorage.setItem('data', JSON.stringify( {name: 'Simon'} ));
    });

    if (viewportWidth < 991){
        $('.btn-about').parent('a').attr('href', 'about.php#aboutCity');
    } 
    else {
        $('.btn-about').parent('a').attr('href', 'about.php');
    }
    

    if (url.indexOf("about.php") > -1){
        var person = JSON.parse(sessionStorage.getItem('data'));
        if (person.name == 'Simon') {
            if (viewportWidth > 991){
                $('.about').hide();
                $('#page6').show();
                $('ul#menu-about li').removeClass('active');
                $('ul#menu-about li').eq(1).addClass('active');
                sessionStorage.removeItem('data');
            }
            else {
                $('.about').show();
            }
        }
        else {
            // do nothing
        }
    }

});




















